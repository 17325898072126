body {
  margin: 0;
  padding: 0;
  --villari-primary: #003366;
  --villari-secondary: #a0beea;
  --villari-accent: #85ffc9;
  --villari-white: #ffffff;
  --villari-black: #231f20;
}

.amplify-textfield {
  --amplify-components-fieldcontrol-border-color: var(--villari-primary);
  --amplify-components-fieldcontrol-focus-border-color: var(--villari-primary);
  --amplify-components-fieldcontrol-focus-box-shadow: 0px 0px 0px 1px var(--villari-primary);
}

.amplify-button {
  --amplify-components-button-color: var(--villari-black);
  --amplify-components-button-border-color: var(--villari-primary);
  --amplify-components-button-focus-color: var(--villari-black);
  --amplify-components-button-focus-border-color: var(--villari-primary);
  --amplify-components-button-focus-box-shadow: 0px 0px 0px 1px var(--villari-primary);
  --amplify-components-button-hover-color: var(--villari-primary);
  --amplify-components-button-hover-background-color: var(--villari-white);
  --amplify-components-button-hover-border-color: var(--villari-primary);
  --amplify-components-button-active-color: var(--villari-black);
  --amplify-components-button-active-border-color: var(--villari-primary);
  --amplify-components-button-active-background-color: var(--villari-white);
}

.amplify-button[data-variation='primary'] {
  background: var(--villari-primary);
  color: var(--villari-white);
  border: 1px solid var(--villari-primary);
}

.amplify-button[data-variation='primary']:hover {
  background: var(--villari-white);
  color: var(--villari-primary);
}

.amplify-button--link {
  color: var(--villari-primary);
}

.amplify-button--link:hover {
  background-color: var(--villari-white);
  color: var(--villari-primary);
  text-decoration: underline;
}

[data-amplify-authenticator][data-variation='modal'] {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 691.4 669.5"><path fill="%2385ffc9" d="M0 0l319.2 669.5h75.4L691.4 0H587.8L385.3 455.9 167.4 0z"/></svg>');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Open Sans';
}

/* [data-amplify-authenticator] [data-amplify-container] {
  position: relative;
}

[data-amplify-authenticator] [data-amplify-container]::before {
  content: 'What\'s crackin\'?';
  color: var(--villari-primary);
  width: 100%;
  height: 3rem;
  position: absolute;
  top: -5rem;
  font-weight: 700;
  font-size: 3rem;
  text-align: left;
} */

[data-amplify-authenticator][data-variation='modal']::after {
  content: '';
  background: url('../../public/logo.svg');
  background-repeat: no-repeat;
  background-position: left;
  color: var(--villari-white);
  width: 100%;
  height: 5rem;
  position: absolute;
  bottom: 0rem;
}

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
  border: 1px solid var(--villari-primary);
}
